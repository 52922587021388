import React from 'react'
import "./section4.css"
import images from '../../../constants/images'

const Section4 = () => {
  return (
    <div className='home3-section4'>
      <div className='h3-sec4-div'>
        <div className='h3-sec4'>
              <div  className='h3-sec4-head'>
                  <p>Reasons to Believe </p>
              </div>
            <div className='h3-sec4-img'>
                  <img src={images.h3_s4_backimg} alt="" />
            </div>
          </div>
      </div>
    </div>
  )
}

export default Section4