import React from 'react'
import "./footer3.css"
import images from '../../../constants/images'

const Footer3 = () => {
  return (
    <div className='home3-footer'>
        <div className='h3-footer'>
            <div className='h3-footer-logo'>
                <img src={images.pro_logo} alt="" />
            </div>
            {/* <div className='footer-del'> */}
                    {/* <div className='foot3-4'>
                        <p className='foot-head'>Contact Us</p>
                        <p className='foot-p'> Fort Lauderdale FL<br/></p>
                        
                    </div> */}
                    {/* <div className='foot3-5'>
                        <p></p>
                        <p className='foot-p'>
                            Cincinnati HQ<br/>
                            1700 Madison Rd<br/>
                            Suite 100<br/>
                            Cincinnati, OH 45206
                            </p>
                    </div> */}
                    {/* <div className='foot3-6'>
                        <p></p>
                        <p className='foot-p'>Arlington, VA 22202<br/>
                            703.570.4108
                        </p>
                    </div> */}
                    {/* <div className='foot3-2'>
                        <p className='foot-head'>Quick Links</p>
                        <p className='foot-p'> What We Do </p>
                        <p className='foot-p'>Who We Are</p>
                        <p className='foot-p'>Partner With Us</p>
                    </div> */}
                    {/* <div className='foot3-3'>
                        <p></p>
                        <p className='foot-p'>Careers</p>
                        <p className='foot-p'>Blog</p>
                    </div> */}
                    
                   
                    {/* <div className='foot3-1'>
                        <p className='foot-head'>Join Our List</p>
                        <input 
                            type="email" 
                            placeholder='Email Address' 
                            className='footer3-input'
                            name='email_users'
                        />
                        <br/>
                        <button>SEND</button>
              
                    </div> */}
            {/* </div> */}
            <hr style={{border:"1px solid grey"}} />
            <div className='footer3-copywrite'>
                
                <div>
                    <p>Copyright © 2024 Pro Vendor Inc. All Rights Reserved.</p>
                </div>
                <div>
                {/* <div className='footer3-social'>
                    <a href="">
                        <div><img src={images.orng_fb} alt="" className='footer-social-img'/></div>
                    </a>  
                    <a href="">
                        <div><img src={images.insta_white} alt="" className='footer-social-img'/></div>
                    </a>        
                    <a href="">
                        <div><img src={images.linked_white} alt="" className='footer-social-img'/></div>
                    </a>   
                </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer3