import logo from './logo.svg';
import './App.css';
import Home3 from './HomePage3/pages/Home3';


function App() {
  return (
    <div >
        <Home3/>
    </div>
  );
}

export default App;
