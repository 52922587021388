import React, { useRef, useState } from 'react'
import "./section9.css"
import emailjs from "emailjs-com";
import images from '../../../constants/images';

const Section9 = () => {
    const [validation, setValidation] = useState({});
    const [hasError, setHasError] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useRef();

    // const [formData, setFormData] = useState({
    //     firstName: "",
    //     lastName: "",
    //     company_name: "",
    //     phone: "",
    //     email: "",
    //     comments: "",
    //   });
      // console.log(formData);
      // const [formErrors, setFormErrors] = useState({});

      // const handleInputChange = (e) => {
      //   const { name, value } = e.target;
      //   setFormData({
      //     ...formData,
      //     [name]: value,
      //   });
      // };
      const [phone, setPhone] = useState('');
    
      const handlePhoneChange = (event) => {
        let inputPhone = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        let formattedPhone = '';
    
        if (inputPhone.length > 0) {
          formattedPhone = inputPhone.slice(0, 3);
    
          if (inputPhone.length > 3) {
            formattedPhone += '-' + inputPhone.slice(3, 6);
          }
          
          if (inputPhone.length > 6) {
            formattedPhone += '-' + inputPhone.slice(6, 10);
          }
        }
    
        setPhone(formattedPhone);
      };
    
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const valid = validateForm(form);


    if (valid) {
      emailjs
      .sendForm(
        "service_6je8lm9",
        "template_uowzwar",
        e.target,
        "Jk14RKPWBZhtASTza"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
            setPopupOpen(true);
            // setLoading(true);
            setTimeout(() => {
              setPopupOpen(false);
              form.reset();
              setPhone(''); // Reset the phone state
            }, 3000);
          },
          (error) => {
            console.log(error.text);
            
            // setHasError(true);
          }
        );
    }
  };

  const validateForm = (form) => {
    const validationData = {};
    let valid = true;
  
    if (!form.elements.from_name.value.trim()) {
      validationData.from_name = "First Name is required!";
      valid = false;
    }
  
    if (!form.elements.lastName.value.trim()) {
      validationData.lastName = "Last Name is required!";
      valid = false;
    }
  
    if (!form.elements.company_name.value.trim()) {
      validationData.company_name = "Company Name is required!";
      valid = false;
    }
    // const phonePattern = /^[0-9]*$/;
    
    const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
    const phone = form.elements.phone.value.trim();
    if (!phone || !phonePattern.test(phone) || phone.length !== 12) {
      validationData.phone ="Invalid phone number. Please enter in the format XXX-XXX-XXXX";
      valid = false;
    }
  
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (
      !form.elements.email.value.trim() ||
      !emailPattern.test(form.elements.email.value.trim())
    ) {
      validationData.email = "Valid Email is required!";
      valid = false;
    }
  
    if (!form.elements.user_comment.value.trim()) {
      validationData.comments = "Comment is required!";
      valid = false;
    }
  
    setValidation(validationData);
    return valid;
  };



    // if (!formData.firstName.trim()) {
    //   errors.firstName = 'First Name is required';
    // } 
    // else if (!/^[A-Za-z]+$/.test(formData.firstName)) {
    //   errors.firstName = 'First Name can only contain letters';
    // }


    // if (!formData.lastName.trim()) {
    //   errors.lastName = 'Last Name is required';
    // } 
    // else if (!/^[A-Za-z]+$/.test(formData.lastName)) {
    //   errors.lastName = 'Last Name can only contain letters';
    // }

    // if (!formData.company_name.trim()) {
    //     errors.company_name = 'Company Name is required';
    //   } 
    //   else if (!/^[A-Za-z]+$/.test(formData.company_name)) {
    //     errors.company_name = 'Company Name can only contain letters';
    //   }

    // if (!formData.phone.trim()) {
    //   errors.phone = 'Phone is required';
    // } else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //   errors.phone = 'Phone number must contain exactly 10 digits and only numbers';
    // }

    // if (!formData.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
    //   errors.email = 'Invalid email format';
    // }

    // if (!formData.comments.trim()) {
    //   errors.comments = 'Message is required';
    // }
    // if (Object.keys(errors).length === 0) {
        
    //     console.log('Form is valid', formData);
    //     setFormErrors({});
    //   } else {
    //     setFormErrors(errors);
    //   }
    // };


    //disable body scrolling

  // const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  // useEffect(() => {
  //   if (showModal1 || showModal) {
  
  //     setBodyScrollEnabled(false);
  //   } else {

  //     setBodyScrollEnabled(true);
  //   }
  //   if (bodyScrollEnabled) {
  //     document.body.style.overflow = "visible";
  //   } else {
  //     document.body.style.overflow = "hidden";
  //   }
  // }, [showModal1, showModal, bodyScrollEnabled]);
// 

  return (
    <div className='home3-section9' id='section9'>
        <div className='h3-sec9'>
            <div className='h3-sec9-part1'>
                <div className='h3-sec9-title'>
                    <p><span className='white-text'>Let's Join</span><br/>   Forces</p>
                </div>
                <div className='h3-sec9-content'>
                    <p>Together, we thrive.  Pro Vendor is serving clients across the nation and is especially glad to cater its services to South Florida businesses. To discover how your business can grow by partnering with our team, please get in touch with us. </p>
                </div>
            </div>
            <div className='h3-sec9-part2'>
                <div className='h-sec9-form'>
                        <form action="" className='form-9' onSubmit={handleFormSubmit} ref={form}>

                                <div className="c_form c_form2">
                                <div className="validation input-data">
                                    <input
                                     type="text" 
                                     placeholder='FIRST NAME'  
                                     className='h3-form-name9'
                                     name="from_name"
                                    //  required
                                    //  onChange={handleInputChange}
                                    />
                                    {/* <label>FIRST NAME</label>  */}
                                    {validation.from_name && (
                                    <div className="val_1">{validation.from_name}</div>
                                    )}
                                </div>
                                <div className="validation input-data">
                                    <input
                                    type="text" 
                                    placeholder='LAST NAME'  
                                    className='h3-form-name9'
                                    name="lastName"
                                    // required
                                    // onChange={handleInputChange}
                                    />
                                    {/* <label>LAST NAME</label> */}
                                    {validation.lastName && (
                                    <div className="val_1">{validation.lastName}</div>
                                    )}
                                </div>
                                </div>
                                <div className="c_form ">
                                    <div className="validation input-data">
                                        <input
                                        type="text" 
                                        placeholder='COMPANY NAME' 
                                        className='h3-form-cmpny9'
                                        name='company_name'
                                        // required
                                        // onChange={handleInputChange}
                                        />
                                        {/* <label>COMPANY NAME</label> */}
                                        {validation.company_name && (
                                        <div className="val_1">{validation.company_name}</div>
                                        )}
                                    </div>
                                    <div className="validation input-data">
                                        <input
                                        type="email" 
                                        placeholder='WORK EMAIL ADDRESS' 
                                        className='h3-form-cmpny9'
                                        name="email"
                                        // required
                                        // onChange={handleInputChange}
                                        />
                                        {/* <label>WORK EMAIL ADDRESS</label> */}
                                        {validation.email && (
                                        <div className="val_1">{validation.email}</div>
                                        )}
                                    </div>
                                    </div>

                                    <div className="c_form ">
                                    <div className="validation1 input-data">
                                        <input 
                                        type="tel" 
                                        placeholder='PHONE NUMBER' 
                                        className='h3-form-phone9'
                                        name="phone"
                                        // pattern="[0-9]*"
                                        maxLength={12}
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        
                                        // required
                                        // onChange={handleInputChange}
                                        />
                                    {/* <label>PHONE NUMBER</label> */}
                                        {validation.phone && (
                                        <div className="val_1">{validation.phone}</div>
                                        )}
                                    </div>
                                    </div>
                                    <div className="c_form ">
                                    <div className="validation1 input-data">
                                        <textarea 
                                        type="text" 
                                        placeholder='COMMENTS' 
                                        className='h3-form-comment9'
                                        name='user_comment'
                                        // required
                                        // onChange={handleInputChange}
                                        />
                                        {/* <label>COMMENTS</label> */}
                                    {validation.comments && (
                                        <div className="val_1">{validation.comments}</div>
                                        )}
                                    </div>
                                    </div>
                            <div className='h3-form-btn9'>
                                <button >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
            </div>
        </div>
        <div className="popup-container">
        {isPopupOpen && (
          <div className="popup">
            <div className="popup-content">
              <div className='popup-head'>
                <img src={images.success_btn} alt="" />
                <h2>Success</h2>
              </div>
              <div>
                {/* <img src={success} alt="" /> */}
              </div>
              
              <p>Your message has been successfully submitted</p>
              {/* <hr /> */}
              {/* <button onClick={closePopup}>Ok</button> */}
            </div>
          </div>
        )}

      {/* {loading && (
        <div className="loading_screen">
         
          <img src={images.Spinnerr} alt="" />
        </div>
      )} */}
      </div>
    </div>
  )
}

export default Section9