import React from 'react'
import "./section1.css"
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-scroll'

const Section1 = () => {
    // const handleGetstart =()=>{
    //     const section9 = document.getElementById('section9'); 
    //     if (section9) {
    //       section9.scrollIntoView({ behavior: 'smooth' }); 
    //     }
    // }
  return (
    <div className='home3-section1'>
        {/* <div className='h3-sec1-flex'> */}
            <div className='h3-sec1-part1'>
            
            </div>
            <div className='h3-sec1-part2'>
                <div className='h3-sec1-content'>
                    <div className='h3-sec1-1'>
                        <p>Your Dedicated <span className='white-text'>Amazon </span>Seller & Vendor Partner </p>
                    </div>
                    <div className='h3-sec1-2'>
                        <p>A to Z solutions include account creation, ranking optimizations, operations, brand management, sales & marketing, and customer service</p>
                        <Link
                        to="section9" // Specify the ID of the target section
                        smooth={true} // Enable smooth scrolling
                        duration={500} // Duration of the scroll animation in milliseconds
                        >
                        <button>GET STARTED</button>
                        </Link>
                    </div>
                </div>
            </div>
        {/* </div> */}
    </div>
  )
}

export default Section1