import React, { useState } from 'react'
import "./Section3.css"
import images from '../../../constants/images';

const Section3 = () => {
    const [buttonImage, setButtonImage] = useState(images.h3_sec3_arrow);
    const [buttonImage1, setButtonImage1] = useState(images.h3_sec3_arrow);
    const [buttonImage2, setButtonImage2] = useState(images.h3_sec3_arrow);
    const [buttonImage3, setButtonImage3] = useState(images.h3_sec3_arrow);
    const handleClick = () => {
        // Toggle between two images on each click
        if (buttonImage === images.h3_sec3_arrow) {
          setButtonImage(images.arrow_btm);
        } else {
          setButtonImage(images.h3_sec3_arrow);
        }
      };
      const handleClick1 = () => {
        // Toggle between two images on each click
        if (buttonImage1 === images.h3_sec3_arrow) {
          setButtonImage1(images.arrow_btm);
        } else {
          setButtonImage1(images.h3_sec3_arrow);
        }
      };
      const handleClick2 = () => {
        // Toggle between two images on each click
        if (buttonImage2 === images.h3_sec3_arrow) {
          setButtonImage2(images.arrow_btm);
        } else {
          setButtonImage2(images.h3_sec3_arrow);
        }
      };
      const handleClick3 = () => {
        // Toggle between two images on each click
        if (buttonImage3 === images.h3_sec3_arrow) {
          setButtonImage3(images.arrow_btm);
        } else {
          setButtonImage3(images.h3_sec3_arrow);
        }
      };
  return (
    <div className='home3-section3'>
        <div className='h3-sec3'>
            <div className='h3-sec3-del-div'>
                <hr />
                <div className='h3-sec3-content content1'>
                    <div className='h3-sec3-content1  '>
                        <p> CONTENT</p>
                    </div>
                    <div className='h3-sec3-content2'>
                        <div className='new-class'>
                            <div className='h3-sec3-h1'>
                                <p className='h3-sec3-p1'>Boost conversion rates with SEO-winning content</p><br/>
                                
                            </div>
                            <div className='h3-sec3-content3'>
                                <button onClick={handleClick} ><img src={buttonImage} alt="" className='h3-sec3-btn-img' /></button>
                            </div>
                        </div>
                        <p className='h3-sec3-p2'>Effective and consumer-friendly content for A9/A10 search algorithms</p>
                        
                    </div>
                    
                </div>
            </div>
            <div className='h3-sec3-del-div'>
                <hr />
                <div className='h3-sec3-content'>
                    <div className='h3-sec3-content1'>
                        <p>Advertising</p>
                    </div>
                    <div className='h3-sec3-content2 '>
                    <div className='new-class'>
                        <div className='h3-sec3-h1'>
                            <p className='h3-sec3-p1'>Drive more sales with tailored ad strategies</p><br/>
                           
                        </div>
                        <div className='h3-sec3-content3'>
                        <button onClick={handleClick1}><img src={buttonImage1} alt="" className='h3-sec3-btn-img' /></button>
                        </div>
                    </div>
                    <p className='h3-sec3-p2'>Get the perfect mix of Sponsored Products, Sponsored Brands, Sponsored Display, and Sponsored TV ads</p>
                    </div>
                    
                </div>
            </div>
            <div className='h3-sec3-del-div1'>
                <hr />
                <div className='h3-sec3-content'>
                    <div className='h3-sec3-content1'>
                        <p >Operations </p>
                    </div>
                    <div className='h3-sec3-content22'>
                    <div className='new-class'>
                        <div className='h3-sec3-h1'>
                            <p className='h3-sec3-p1'>Inventory management and planning</p><br/>
                            
                        </div>
                        <div className='h3-sec3-content3'>
                        <button onClick={handleClick2}><img src={buttonImage2} alt="" className='h3-sec3-btn-img' /></button>

                    </div>
                    </div>
                    <p className='h3-sec3-p2 h3-sec3-p3'>No more worries about replenishments, lost inventory recovery, reimbursements, shipping optimizations</p>
                    </div>
                    
                </div>
            </div>
            <div className='h3-sec3-del-div'>
                <hr />
                <div className='h3-sec3-content'>
                    <div className='h3-sec3-content1'>
                        <p>Account Health </p>
                    </div>
                    <div className='h3-sec3-content22' style={{height:"167px"}}>
                    <div className='new-class'>
                        <div className='h3-sec3-h1'>
                            <p className='h3-sec3-p1'>Running a tight ship is critical to your success</p><br/>
                          
                        </div>   
                        <div className='h3-sec3-content3'>
                        <button onClick={handleClick3}><img src={buttonImage3} alt="" className='h3-sec3-btn-img' /></button>
                    </div> 
                    </div>
                    <p className='h3-sec3-p2 h3-sec3-p4'>Let us handle those nasty details page and account suspensions, compliance and category requirements, and improve your performance metrics
                            </p>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section3