import React from 'react'
import Section1 from '../container/section1/Section1'
import "./home3.css"
import Section2 from '../container/section2/Section2'
import Section3 from '../container/section3/Section3'
import Section4 from '../container/section4/Section4'
import Section5 from '../container/section5/Section5'
import Section6 from '../container/Section6/Section6'
import Section7 from '../container/section7/Section7'
import Section8 from '../container/section8/Section8'
import Section9 from '../container/section9/Section9'
import Navbar from '../container/Navbar/Navbar'
import Footer3 from '../container/Footer3/Footer3'

const Home3 = () => {
  return (
    <div>
        <Navbar/>
        <Section1/>
        <Section2 id="section2"/>
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6 id="section6"/>
        <Section7/>
        <Section8/>
        <Section9 id="section9"/>
        <Footer3/>
    </div>
  )
}

export default Home3