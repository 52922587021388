import React, { useRef } from 'react'
import "./section8.css"
import Slider from 'react-slick';
import images from '../../../constants/images';


const Section8 = () => {
    const sliderRef = useRef(null);

    const handleNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const handlePrevSlide = () => {
        sliderRef.current.slickPrev();
    };
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                },
              },
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            
      ]};

  return (
    <div className='home3-section8'>
        <div className='h3-sec8'>
            <div className='h3-sec8-top'>
                <div className='h3-sec8-top1'>
                    <p>Service Insights</p>
                </div>
                <div className='h3-sec8-top2'>
                    <button   onClick={handlePrevSlide}>
                        <img src={images.h3_sec8_left} alt="" className='h3-sec8-btn-img'/>
                    </button>
                    <button onClick={handleNextSlide}>
                        <img src={images.h3_sec8_right} alt="" className='h3-sec8-btn-img'/>
                    </button>
                </div>
                </div>
                <div className='h-sec8-slider'>
                <Slider {...settings} className='h3-sec8-cards-div'  ref={sliderRef} >
                    <div className='h3-sec8-card'>
                        <div className='h3-sec8-card-img'>
                            <img src={images.service_optimization} alt="" />
                        </div>
                        <div className='h3-sec8-card-title'>
                            <p>Search Optimization</p>
                        </div>
                        <div className='h3-sec8-card-des'>
                            <p>Getting to Page 1 with relevant keywords is no easy task. Solution: super-optimize your details pages with research, data analysis, and a little help from AI.</p>
                        </div>
                    </div>
                    <div className='h3-sec8-card'>
                        <div className='h3-sec8-card-img'>
                            <img src={images.profitability} alt="" />
                        </div>
                        <div className='h3-sec8-card-title'>
                            <p>Profitability Calculation </p>
                        </div>
                        <div className='h3-sec8-card-des'>
                            <p>Understanding how much you're making on a sale is critical. Fees, promos, lost inventory, refunds etc., all affect your bottom line. Solution: Repeated SKU-level math and constant check on the reported numbers.</p>
                        </div>
                    </div>
                    <div className='h3-sec8-card'>
                        <div className='h3-sec8-card-img'>
                            <img src={images.h3_sec3_card3} alt="" />
                        </div>
                        <div className='h3-sec8-card-title'>
                            <p>Shipping and Inventory Management</p>
                        </div>
                        <div className='h3-sec8-card-des'>
                            <p>Shipping costs are higher than ever. Optimizing your shipments is a must. So is having all your shipped products accounted for. Also, never be out of stock. Solution: Shop around, plan ahead, document everything, and follow up.</p>
                        </div>
                    </div>
                    <div className='h3-sec8-card'>
                        <div className='h3-sec8-card-img'>
                            <img src={images.h3_sec3_card2} alt="" />
                        </div>
                        <div className='h3-sec8-card-title'>
                            <p>Customer Service</p>
                        </div>
                        <div className='h3-sec8-card-des'>
                            <p>Amazon shoppers are one of the most supported customers, which played a giant role in the company’s success. The expectations are high. Solution: Resolve every customer issue and do it quickly.</p>
                        </div>
                    </div>
                </Slider>
            </div>
            

        </div>
    </div>
  )
}

export default Section8