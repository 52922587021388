import React from 'react'
import "./Section2.css"
import images from '../../../constants/images'



const Section2 = () => {
  return (
    <div className='home3-section2' id="section2">
        <div className='h3-sec2-'>
        <hr className='h3-sec2-hr'/>
        <div className='h3-sec2'>
            <div className='h3-sec2-head'>
                <div className='cir-text-div'>
                    <img src={images.h3_sec2_bg} alt="" />
                    <div className='h3-sec2-title'>
                        <p> <span className='white-text'>Full or S</span>upplemental<br/> <span className='white-text'>Service</span>s Tailored to Your Brand and Category Needs</p>
                    </div>
                </div>
                
                <div className='h3-sec2-des'>
                    <p>Pro Vendor is Florida’s leading Amazon service provider working with top-selling brands on Amazon. Leverage our full-service approach, channel expertise and in-house solutions to accelerate your growth and maximize profitability on Amazon and other marketplaces.
                </p>
                </div>
            </div>
            <div className='h3-sec2-img'>
                
                    <img src={images.mobile3} alt="" />
               
            </div>
        </div>
        </div>
    </div>
  )
}

export default Section2