import React, { useState } from 'react'
import "./section6.css"
import images from '../../../constants/images';

const Section6 = () => {
 
    const [buttonImagetop, setButtonImagetop] = useState(images.arrow_btm);
    const [buttonImage, setButtonImage] = useState(images.h3_sec3_arrow);
    const [buttonImage1, setButtonImage1] = useState(images.h3_sec3_arrow);
    const [buttonImage2, setButtonImage2] = useState(images.h3_sec3_arrow);

    const [showDetails, setShowDetails] = useState(false);
    const handleClicktop = () => {
        // Toggle between two images on each click
        if (buttonImagetop === images.h3_sec3_arrow) {
          setButtonImagetop(images.arrow_btm);
        } else {
          setButtonImagetop(images.h3_sec3_arrow);
        }
      };
    const handleClick = () => {
        setShowDetails(!showDetails);
        // Toggle between two images on each click
        if (buttonImage === images.h3_sec6_orange_btn) {
          setButtonImage(images.h3_sec3_arrow);
        } else {
          setButtonImage(images.h3_sec6_orange_btn);
        }
      };
      const handleClick1 = () => {
        // Toggle between two images on each click
        if (buttonImage1 === images.h3_sec6_orange_btn) {
          setButtonImage1(images.h3_sec3_arrow);
        } else {
          setButtonImage1(images.h3_sec6_orange_btn);
        }
      };
      const handleClick2 = () => {
        // Toggle between two images on each click
        if (buttonImage2 === images.h3_sec6_orange_btn) {
          setButtonImage2(images.h3_sec3_arrow);
        } else {
          setButtonImage2(images.h3_sec6_orange_btn);
        }
      };

      const handleGetstart =()=>{
        const section9 = document.getElementById('section9'); // Get reference to Section9 element
        if (section9) {
          section9.scrollIntoView({ behavior: 'smooth' }); // Scroll to Section9 with smooth animation
        }
    }
    
  return (
    <div className='home3-section6' id="section6">
      <div className='h3-sec6'>
        <div className='h3-sec6-part1'>
          <div className='h3-sec6-head'>
            <p>Our Clients </p>
          </div>
          <div className='h3-sec6-del'>
            <p>We’ve worked with nearly 70 brands and roughly 2000 SKUs on Amazon over the years and managed hundreds of millions of dollars in sales. If your company is ready to break through the next big sales threshold you are at the right place.  </p>
          </div>
        </div>
        <div className='h3-sec6-part2'>
          <div className='h3-see-client'>
            <p >See our clients’ results and learn what we can do for you</p>
            <button className='h3-client-btn' onClick={handleClicktop}>
              <img src={buttonImagetop} alt="" />
            </button>
          </div>
          <div>
            <div className='h3-sec6-del-div'>
              <hr />
              <div className={showDetails ? 'h3-sec6-content11' : 'h3-sec6-content'}>
                <div className='h3-sec6-content1'>
                  <p>DIETARY SUPPLEMENT COMPANY</p>
                </div>
                <div className={showDetails ? 'h3-sec6-content22' : 'h3-sec6-content2'}  >
                  <div className='new-class1'>
                    <div className='h3-sec6-h1'>
                      <p className='h3-sec3-p1'>From $300k to $500k per month sales in six months</p> 
                    </div>
                    <div className='h3-sec6-content3 h3-sec6-content33'>
                      <button className='h3-client-btn' onClick={handleClick}><img src={buttonImage} alt="" /></button>
                    </div>
                  </div>
                    {/* <div className={showDetails ? 'h3-sec3-details show' : 'h3-sec3-details '}>
                      <p className='h3-sec3-p2'>In their first full quarter, we helped MobyFox:</p>
                      <p className='h3-sec3-p2'>
                      - Rank organically on 100+ priority search terms<br/> 
                      - Achieve ROAS of over 10 – more than 3x the Amazon average <br/>
                      - Sell through $580K of product during the 5-week holiday season
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
            <div className='h3-sec6-del-div'>
              <hr />
              <div className='h3-sec6-content'>
                <div className='h3-sec6-content1'>
                  <p>MEDICAL SUPPLY COMPANY</p>
                </div>
                <div className='h3-sec6-content2'>
                  <div className='new-class1'>
                    <div className='h3-sec6-h1'>
                      <p className='h3-sec3-p1'>From $40k to $500k per month sales in one year</p>
                      {/* <p className='h3-sec3-p2'>See how MobyFox sold 17,500 watch bands with Amify’s help within 90 days after launch</p> */}
                    </div>
                    <div className='h3-sec6-content3'>
                      <button className='h3-client-btn' onClick={handleClick1}><img src={buttonImage1} alt="" /></button>
                    </div>
                  </div>
                  {/* <div className={showDetails ? 'h3-sec3-details show' : 'h3-sec3-details '}>
                        <p className='h3-sec3-p2'>In their first full quarter, we helped MobyFox:</p>
                        <p className='h3-sec3-p2'>
                        - Rank organically on 100+ priority search terms<br/> 
                        - Achieve ROAS of over 10 – more than 3x the Amazon average <br/>
                        - Sell through $580K of product during the 5-week holiday season
                        </p>
                    </div> */}
                </div>  
              </div>
            </div>
            <div className='h3-sec6-del-div'>
              <hr />
              <div className='h3-sec6-content'>
                <div className='h3-sec6-content1'>
                  <p>BEAUTY COMPANY</p>
                </div>
                <div className='h3-sec6-content2'>
                  <div className='new-class1'>
                    <div className='h3-sec6-h1'>
                      <p className='h3-sec3-p1'>From $0 to $10k per month sales in four months</p>
                      {/* <p className='h3-sec3-p2 h3-sec3-p3'>See how we helped a beauty brand reduce MAP violations by 99% and unauthorized sellers by 81% in 12 months.</p> */}
                    </div>
                    <div className='h3-sec6-content3'>
                      <button className='h3-client-btn' onClick={handleClick2}><img src={buttonImage2} alt="" /></button>
                    </div>
                  </div>
                    {/* <div className={showDetails ? 'h3-sec3-details show' : 'h3-sec3-details '}>
                                    <p className='h3-sec3-p2'>In their first full quarter, we helped MobyFox:</p>
                                    <p className='h3-sec3-p2'>
                                    - Rank organically on 100+ priority search terms<br/> 
                                    - Achieve ROAS of over 10 – more than 3x the Amazon average <br/>
                                    - Sell through $580K of product during the 5-week holiday season
                                    </p>
                                </div> */}
                  </div>  
                </div>
                <hr />
              </div>
            </div>

          </div>
          <div className='h3-sec6-button-div'>
            <button className='h3-sec6-button' onClick={handleGetstart}>
                SEE WHAT WE CAN DO FOR YOU
            </button>
          </div>
      </div>
    </div>
  )
}

export default Section6