import React, { useState } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import "./navbar.css"
import images from '../../../constants/images';

const Navbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

  const handleCheckboxChange = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
        <nav className="navbar3">
            <div className={`navbar-container3 container1 ${isMenuOpen ? 'navbar-container3-new' : ''}`}>
                <input type="checkbox" name="" id="menuCheckbox" onChange={handleCheckboxChange} />
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
                <a href="" className="title_nav_bar">
                    <img
                    src={images.pro_logo}
                    className={`logo3 ${isMenuOpen ? 'hide' : ''}`}
                    alt=""
                    />
                </a>
            </div>
            
            <div className={`navbar3-items ${isMenuOpen ? 'navbar3-items-open' : ''}`}>    
                <ul className={`menu-items ${isMenuOpen ? 'menu-items1' : ''}`}>
                    <li>
                    <ScrollLink to="section2" smooth={true} duration={500} spy={true} >
                        What We Do
                    </ScrollLink>
                    </li>
                    <li>
                    <ScrollLink to="section6" smooth={true} duration={500}  spy={true}>
                        Clients
                    </ScrollLink>
                    </li>
                    <li>
                    <ScrollLink to="section9" smooth={true} duration={500}  spy={true}>
                        Contact
                    </ScrollLink>
                    </li>
                </ul>
                {/* <a href="" className="title_nav_bar">
                    <img
                    src={images.pro_logo}
                    className="header_logo3 logo3"
                    alt=""
                    />
                </a> */}
            </div>
        </nav>
    </div>
  );
};

export default Navbar;