import React, { useRef } from 'react'
import "./section7.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from '../../../constants/images';

const Section7 = () => {
    const sliderRef = useRef(null);
    const handleNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const handlePrevSlide = () => {
        sliderRef.current.slickPrev();
    };
    var settings = { 
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
      ]};
  return (
    <div className='home3-section7'>
        <div className='h3-blk-div'>
            <div className='h3-sec7-hr'>
                <hr />
            </div>
            <div className='h3-sec7'>
                <div className='h3-sec7-flex'>
                    <div className='h3-sec7-clients'>
                        <p>Clients</p>
                    </div>
                    <div  className='h3-sec7-logos'>
                        {/* <div className='h3-sec7-logos1'> */}
                            <div className='h3-sec7-logo-div'><img src={images.forever_logo} alt="" className='h3-sec7-logo-img h3-lg1' /></div>
                            <div className='h3-sec7-logo-div'><img src={images.mobyfox_logo} alt="" className='h3-sec7-logo-img h3-lg5' /></div>
                            <div className='h3-sec7-logo-div'><img src={images.hanz_logo} alt="" className='h3-sec7-logo-img h3-lg2' /></div>
                            <div className='h3-sec7-logo-div'><img src={images.fab} alt="" className='h3-sec7-logo-img h3-lg3' /></div>
                        {/* </div> */}
                        {/* <div className='h3-sec7-logos2'> */}
                            <div className='h3-sec7-logo-div'><img src={images.mayts_logo} alt="" className='h3-sec7-logo-img h3-lg1' /></div>
                        <div className='h3-sec7-logo-div'><img src={images.Vf_corporation_logo} alt="" className='h3-sec7-logo-img h3-lg5' /></div>
                        <div className='h3-sec7-logo-div'><img src={images.energizer_logo} alt="" className='h3-sec7-logo-img  h3-lg2' /></div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
            </div>
            <div className='h3-sec7-part2'>
                {/* <div className='h3-sec7-top2'>
                    <button onClick={handlePrevSlide}>
                       <img src={images.h3_sec7_left} alt="" className='h3-sec7-btn-img'/>
                    </button>
                    <button onClick={handleNextSlide}>
                        <img src={images.h3_sec7_right} alt="" className='h3-sec7-btn-img'/>
                    </button>
                </div> */}
                <div className='h3-sec7-slider'>
                    {/* <Slider {...settings} className='h3-sec8-cards-div' ref={sliderRef}> */}
                    <div className='h3-sec7-cards-div' >
                        <div className='h3-sec7-card' >
                            <div className='h3-sec7-1'>
                                <div className='h3-sec7-card-img'>
                                    <img src={images.h3_sec7_client1} alt="" />
                                </div>
                                <div className='h3-sec7-card-content'>
                                    <div className='h3-sec7-card-top'>
                                        <div className='h3-sec7-top1'>
                                            <p>“</p>
                                        </div>
                                    </div>
                                    <div >
                                        <p className='h3-sec7-des'>“It’s been over 10 years since I first touched a Seller Central and a Vendor Central account and it defined a path to a success story I love to share with people around me. With years of continuous learning, self-improvement, great failures, and great accomplishments, Pro Vendor realized a dream I’ve had for so many years. Along the journey a great team has formed that built meaningful and incredibly exciting business and personal relationships. A handful of mentors guided our path in the right direction when help was needed. We didn’t build an agency, we created something more purposeful. We established a structure where, in most cases, we are a deeply integrated part of our clients’ operations.”</p><br/>                    
                                    </div>
                                    <div className='h3-sec7-card-btm'>
                                        <p className='h3-sec7pp1'>-Ferenc Veres, <span className='orange-txt'>Founder</span> </p>
                                        <p className='h3-sec7pp'>”</p>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        {/* <div className='h3-sec8-card' >
                            <div className='h3-sec8-1'>
                                <div className='h3-sec7-card-img'>
                                    <img src={images.h3_sec7_client1} alt="" />
                                </div>
                                <div className='h3-sec7-card-content'>
                                    <div className='h3-sec7-card-top'>
                                        <div className='h3-sec7-top1'>
                                            <p>“</p>
                                        </div>
                                    </div>
                                    <div >
                                        <p className='h3-sec7-des'>“It’s been over 10 years since I first touched a Seller Central and a Vendor Central account and it defined a path to a success story I love to share with people around me. With years of continuous learning, self-improvement, great failures, and great accomplishments, Pro Vendor realized a dream I’ve had for so many years. Along the journey a great team has formed that built meaningful and incredibly exciting business and personal relationships. A handful of mentors guided our path in the right direction when help was needed. We didn’t build an agency, we created something more purposeful. We established a structure where, in most cases, we are a deeply integrated part of our clients’ operations.”</p><br/>                    
                                    </div>
                                    <div className='h3-sec7-card-btm'>
                                        <p className='h3-sec7pp1'> -Ferenc Veres, <span className='orange-txt'> Founder</span> </p>
                                        <p className='h3-sec7pp'>”</p>
                                    </div>
                                </div>    
                            </div>
                        </div> */}
                    {/* </Slider> */}
                    </div>
                </div>

            </div>
        {/* </div> */}
    </div>
  )
}

export default Section7