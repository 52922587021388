import React from 'react'
import "./section5.css"
import images from '../../../constants/images'


const Section5 = () => {
  return (
    <div className='home3-section5'>
      
            <div className='h3-sec5-head'>
                <div className='circle-text-div'>
                    <img src={images.h3_sec2_bg} alt=""  className='h3-sec5-head-img' />
                    <div className='h3-sec5-title'>
                      <p> <span className='white-text'>A Trus</span>ted Partner,<br/><span className='white-text'>Prove</span>n Across Every Category</p>
                    </div>
                </div>

                <div className='h3-sec5-flex'>
                    <div className='h3-sec5-1'>
                        
                        <div className='h3-sec5-des'>
                            <p>Our detailed approach eliminates the inefficiencies and channel conflicts. We function as an in-house team, aligning our goals with yours.</p><br/>
                            <p> The focus on your business, including expert guidance on Amazon and other online marketplaces, is our top priority.
                            </p>
                        </div>
                    </div>
                    <div className='h3-sec5-2'>
                        <img src={images.h3_s4_amznlogo} alt="" />
                    </div>
                </div>
                
            </div>
     
    </div>
  )
}

export default Section5