import hero from "../Assets/images/Hero.jpeg"
import logo from "../Assets/images/logo.png"
import pro_logo from "../Assets/images/provendor-logo.png"
import search from "../Assets/images/Search Icon.png"
import mobile from "../Assets/images/Mask group (1).png"
import close from "../Assets/images/Close Icon.png"
import imgg from "../Assets/images/yender-gonzalez-lX5OfZFjHWk-unsplash 1.png"
import cardlogo1 from "../Assets/images/Transfer_long_top.png"
import cardlogo2 from "../Assets/images/Chart_alt_fill.png"
import cardlogo3 from "../Assets/images/User_fill.png"
import cardlogo4 from "../Assets/images/Order_fill.png"
import reasonstobelieve from  "../Assets/images/Reasons to Believe.png"
import sectio4back from "../Assets/images/section4_back.png"
import appleipad from "../Assets/images/Apple iPad Pro 13_ Silver - Landscape.png"
import section6img from "../Assets/images/section6.png"
import img_foot from "../Assets/images/img.png"
import fb from "../Assets/images/fb.png"
import insta from "../Assets/images/inst.png"
import linkedin from "../Assets/images/linkedin.png"
import wha_we_do from "../Assets/images/What We Do.png"
import diagram from "../Assets/images/diagram.png"
import casestudies from "../Assets/images/Case Studies.png"
import client1 from "../Assets/images/client1.png"
import client2 from "../Assets/images/client2.png"
import client3 from "../Assets/images/client3.png"
import mayts_logo from "../Assets/images/logos/client-balanced-tiger.png"
import energizer_logo from "../Assets/images/logos/client-boisson.png"
import hanz_logo from "../Assets/images/logos/client-hairmax.png"
import Vf_corporation_logo from "../Assets/images/logos/client-hproof.png"
import fab from "../Assets/images/logos/client-phg.png"
import mobyfox_logo from "../Assets/images/logos/client-relaxium.png"
import forever_logo from "../Assets/images/logos/forever.jpeg"
import cassestudy2 from "../Assets/images/Group 67.png"


import hom2_logo from "../Assets/images/home2logo.png"
import home2_star from "../Assets/images/Star 1.png"
import amazon_btm from "../Assets/images/amazon-bottom.png"
import home2_illution from "../Assets/images/hom2-illution.png"
import home2_get_btn from "../Assets/images/home2-get-button.png"
import h2_star2 from "../Assets/images/Star 2.png"
import button from "../Assets/images/Ellipse 2 (1).png"
import misc from "../Assets/images/Misc_04.png"
import misc2 from "../Assets/images/Highlight_12.png"
import sec2_back_img from "../Assets/images/section2-back-img.png"
import h_sec2_back from "../Assets/images/h-sec2-back.png"
import gr8 from "../Assets/images/Group 8.png"
import cardimg1 from "../Assets/images/Transfer (1).png"
import cardimg2 from "../Assets/images/Transfer (3).png"
import cardimg3 from "../Assets/images/Transfer (2).png"
import cardimg4 from "../Assets/images/Transfer (4).png"
import h_sec4_back from "../Assets/images/Group 67.png"
import h_sec4_diagram from "../Assets/images/h-sec4-diagram.png"
import h_sec5_img from "../Assets/images/h-sec5-img.png"
// import willow from "../Assets/images/Nature’s Willow.png"
import moby from "../Assets/images/MobyFox.png"
import h_sec7_misc from "../Assets/images/h-sec7-misc.png"
import h_sec7_card_img1 from "../Assets/images/image 7 (1).png"
import h_sec7_card_img2 from "../Assets/images/image 8.png"
import h_sec8_card_img1 from "../Assets/images/kevin-matos-Nl_FMFpXo2g-unsplash 1.png"
import h_sec8_card_img2 from "../Assets/images/ruthson-zimmerman-Ws4wd-vJ9M0-unsplash 1.png"
import h_sec8_card_img3 from "../Assets/images/mediensturmer-aWf7mjwwJJo-unsplash 1.png"
import h_sec9_hand from "../Assets/images/h-sec9-hand.png"
import h_sec9_btm_img from "../Assets/images/illustration (4).png"


import home3_sec1_back from "../Assets/images/img Hero.png"
import h3_sec2_bg from "../Assets/images/bg.png"
import mobile3 from "../Assets/images/iPhone 12 Pro 1.png"
import h3_sec3_arrow from "../Assets/images/Vector 2.png"
import h3_s4_backimg from "../Assets/images/Stats Graphics .png"
import h3_s4_amznlogo from "../Assets/images/logo amazon.png"
import arrow_btm from "../Assets/images/arrow-btm6.png"

// import h3_sec7_client1 from "../Assets/images/enchanting-blinde-woman-in-trendy-knitted-sweater-expressing-happiness-indoor-portrait-of-charming-european-woman-standing-on-orange 1.png"
import h3_sec7_client1 from "../Assets/images/Ferenc-Veres.jpg"
import h3_sec3_card1 from "../Assets/images/austin-distel-744oGeqpxPQ-unsplash 1.png"
import h3_sec3_card2 from "../Assets/images/james-yarema-rZLIRuBW6Ac-unsplash 1.png"
import h3_sec3_card3 from "../Assets/images/bryan-angelo-FtiXADBTqGY-unsplash 1.png"
import h3_sec9_bg from "../Assets/images/contact-back.png"
import orng_fb from "../Assets/images/Facebook - Negative (2).png"
import insta_white from "../Assets/images/insta-white.png"
import linked_white from "../Assets/images/linked-white.png"
import h3_sec7_left from "../Assets/images/Vector 11.png"
import h3_sec7_right from "../Assets/images/Vector 10.png"
// import h3_sec3_card2 from "../Assets/images/customer_Service.jpg"

import service_optimization from "../Assets/images/services-optimization.png"
import profitability from "../Assets/images/austin-distel-744oGeqpxPQ-unsplash 1.png"
import h3_sec8_left from "../Assets/images/Vector 13.png"
import h3_sec8_right from "../Assets/images/Vector 12.png"
import h3_sec6_orange_btn from "../Assets/images/Vector 7.png"
// import success_btn from  "../Assets/images/success-btn.png"
import success_btn from "../Assets/images/success-btn.png"


export default {
    hero,
    logo,
    pro_logo,
    search,
    mobile,
    close,
    imgg,
    cardlogo1,
    cardlogo2,
    cardlogo3,
    cardlogo4,
    reasonstobelieve,
    sectio4back,
    appleipad,
    section6img,
    img_foot,
    fb,
    insta,
    linkedin,
    wha_we_do,
    diagram,
    casestudies,
    client1,
    client2,
    client3,
    hanz_logo,
    mayts_logo,
    mobyfox_logo,
    Vf_corporation_logo,
    fab,
    energizer_logo,
    forever_logo,
    cassestudy2,

    // home_page_2
    hom2_logo,
    home2_star,
    amazon_btm,
    home2_illution,
    home2_get_btn,
    home2_star,
    h2_star2,
    button,
    misc,
    misc2,
    sec2_back_img,
    h_sec2_back,
    gr8,
    cardimg1,
    cardimg2,
    cardimg3,
    cardimg4,
    h_sec4_back,
    h_sec4_diagram,
    h_sec5_img,
    // willow,
    moby,
    h_sec7_misc,
    h_sec7_card_img1,
    h_sec7_card_img2,

    service_optimization,
    profitability,
    h_sec8_card_img1,
    h_sec8_card_img2,
    h_sec8_card_img3,
    h_sec9_hand,
    h_sec9_btm_img,

    home3_sec1_back,
    h3_sec2_bg,
    mobile3,
    h3_sec3_arrow,
    h3_s4_backimg,
    h3_s4_amznlogo,
    arrow_btm,
    h3_sec7_client1,
    h3_sec3_card1,
    h3_sec3_card2,
    h3_sec3_card3,
    h3_sec9_bg,
    orng_fb,
    insta_white,
    linked_white,
    h3_sec7_left,
    h3_sec7_right,
    h3_sec8_left,
    h3_sec8_right,
    h3_sec6_orange_btn,
    success_btn,
};
